<template>
<div>
<el-row :gutter="20">
    <el-col :span="6">
      <div class="box-card">
        <div class="icon">
          <i class="el-icon-user-solid"></i>
        </div>
        <div class="info">
          <b>学员统计</b>
          <p>{{this.studentNumber}} 人<br><span>(系统使用人数统计)</span></p>
        </div>
      </div>
    </el-col>
    <el-col :span="6">
        <div class="box-card">
          <div class="iconTwo">
            <i class="el-icon-s-custom"></i>
          </div>
          <div class="info">
            <b>班级统计</b><p>{{this.classNumber}} 人<br><span>(系统使用人数统计)</span></p>
          </div>
        </div>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col :span="12">
      <div class="grid-contentTwo bg">
        <div class="title">班级分数统计</div>
        <div class="attendSel">
          <span>班级：</span>
            <el-select v-model="className" class="in3"  placeholder="请选择班级">
              <el-option v-for="(i,index) in dataList2" :key="index" :value="i.classId" :label="i.className"></el-option>
            </el-select>
          <el-button type="primary" @click="attendSel()">查询</el-button>
        </div>
        <ve-pie :data="fractionData" :title="chartSettings.title"></ve-pie>
      </div>
    </el-col>
    <el-col :span="12">
      <div class="grid-contentTwo bg">
      <div class="title">学员考试完成率统计</div>
      <div class="attendSel">
          <el-select placeholder="请选择班级" v-model="classNameTwo"
                     @change="classChange"
                     filterable
                     clearable>
            <el-option v-for="(item,index) in dataList2"
                       :key="index"
                       :label="item.className"
                       :value="item.classId"/>
          </el-select>
        <span>  试卷：</span>
        <el-select placeholder="请选择试卷" v-model="testName"
                     @change="groupChange"
                     filterable
                     clearable>
            <el-option v-for="(item,index) in groupData"
                       :key="index"
                       :label="item.testName"
                       :value="item.id"/>
          </el-select>
      <el-button type="primary" @click="attendSelTwo()">查询</el-button>
      </div>
      <ve-pie :data="pieData"></ve-pie>
      </div>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col :span="12">
      <div class="grid-contentTwo bg">
        <div class="title">性别统计</div>
        <ve-pie :data="sexData" :title="chart2Settings.title"></ve-pie>
      </div>
    </el-col>
    <el-col :span="12">
      <!-- <div class="grid-contentTwo bg"> -->
        <!-- <div class="title">年龄统计</div> -->
        <!-- <ve-pie :data="BirthData" :title="chartSettings.title"></ve-pie> -->
      <!-- </div> -->
    </el-col>
  </el-row>
  </div>
</template>

<script>
import 'echarts/lib/component/title'
import { getAllClassByMoHu } from '@/api/user.js'
import { getStudentNum, getClassNum, getSexNum, getBirthNum, achievement, getListTestByClassId, StuTestCompletionRate } from '@/api/training.js'
  export default {
    name: 'Presentation',
    data() {
      return {
        listLoading: false,
        studentNumber: '',
        classNumber: '',
        SexNum: '',
        BirthNum: '',
        fractionNum: '',
        sexData: {
          columns: ['NAME', 'VALUE'],
          rows: []
        },
        BirthData: {
          columns: ['ageRange', 'count'],
          rows: []
        },
        fractionData: {
          columns: ['name', 'value'],
          rows: []
        },
        chart2Settings: {
          // title: {
          //   text: '性别',
          //   left: '40%',
          //   bottom: '43%',
          //   padding: [24, 16],
          //   borderRadius: 50,
          //   backgroundColor: '#ffffff',
          //   textStyle: {
          //   }
          // }
        },
        chartSettings: {
          // title: {
          //   text: '年龄',
          //   left: '40%',
          //   bottom: '43%',
          //   padding: [24, 16],
          //   borderRadius: 50,
          //   backgroundColor: '#ffffff',
          //   textStyle: {
          //   }
          // }
        },
        dataList2: [],
        className: '',
        classNameTwo: '',
        testName: '',
        groupData: [], // 存放试卷信息
        zongNum: '',
       detailsList: {},
       list: [{}],
      pieData: {
        columns: ['number', 'total'],
        rows: [
          { number: '已完成', total: '' },
          { number: '未完成', total: '' }
        ]
      }
      }
    },
    mounted() {
      this.getStudentNum()
      this.getClassNum()
      this.getSexNum()
      this.getBirthNum()
      this.getAllClassByMoHu()
    },
    methods: {
       // 获取班级列表
      getAllClassByMoHu: function () {
          getAllClassByMoHu(this.listQuery).then(resp => {
            if (resp.data.code === '0') {
              this.dataList2 = resp.data.data.rows
            }
          })
      },
 // 统计学生人数
      getStudentNum() {
        getStudentNum().then(res => {
          if (res.data.code === '200') {
            this.studentNumber = res.data.data
          }
        })
      },
      // 统计班级
      getClassNum() {
        getClassNum().then(res => {
          if (res.data.code === '200') {
            this.classNumber = res.data.data
          }
        })
      },
      // 统计性别
      getSexNum() {
        getSexNum().then(res => {
          if (res.data.code === '200') {
            this.SexNum = res.data.data
            for (var i = 0; i < this.SexNum.length; i++) {
               this.sexData.rows.push({
                NAME: this.SexNum[i].NAME,
                VALUE: this.SexNum[i].VALUE
               })
            }
          }
        })
      },
      // 统计年龄
      getBirthNum() {
        getBirthNum().then(res => {
          if (res.data.code === '200') {
            this.BirthNum = res.data.data
            for (var i = 0; i < this.BirthNum.length; i++) {
               this.BirthData.rows.push({
                ageRange: this.BirthNum[i].ageRange,
                count: this.BirthNum[i].count
               })
            }
          }
        })
      },
      // 统计分数与学员人数
      attendSel() {
        this.fractionData.rows = []
        const classId = this.className
        this.listLoading = true
        achievement(classId).then(res => {
         if (res.data.code === '200') {
           this.fractionNum = res.data.data
            this.listLoading = false
            for (var i = 0; i < this.fractionNum.length; i++) {
               this.fractionData.rows.push({
                name: this.fractionNum[i].name + '分',
                value: this.fractionNum[i].value
               })
            }
         }
        })
      },
      // 选择班级后获取试卷id name
      classChange() {
        const classId = this.classNameTwo
        getListTestByClassId(classId).then(res => {
          this.groupData = res.data.data
        })
        if (this.classId === null || this.classId === '') {
          this.testName = null
        }
      },
      groupChange() {},
      // 学员考试完成率统计
      attendSelTwo() {
        if (this.testName === '') {
          this.$notify({
              message: '请选择试卷',
              type: 'error'
          })
        } else if (this.classNameTwo === '') {
          this.$notify({
              message: '请选择班级',
              type: 'error'
          })
        } else {
          const query = {
            testId: this.testName,
            classId: this.classNameTwo
          }
           this.pieData.rows[0].total = ''
           this.pieData.rows[1].total = ''
          StuTestCompletionRate(query).then(res => {
          if (res.data.code === '200') {
              this.pieData.rows[0].total = res.data.data.testFinishTotal
              this.pieData.rows[1].total = res.data.data.testUnFinishTotal
          }
          })
        }
      }
    }
  }
</script>

<style scoped>
 .el-row {
    margin-bottom: 20px;
  }
.title{ text-align: left; text-indent:2em; padding-bottom: 10px;}
.attendSel{ padding-bottom: 20px;}
.attendSel span{font-size: 12px;}
.grid-contentTwo{ padding: 20px 0px;}
.bg-purple { background: #d3dce6;}
.box-card{ display: flex; box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
border: 1px solid #EBEEF5;
    background-color: #FFF;
    color: #303133;
    transition: .3s; padding: 20px;border-radius: 4px;}
.iconTwo{ width: 60px; height: 60px; margin-top: 10px; line-height: 60px; font-size: 40px; color: #fff; border-radius: 50%; background:rgb(56, 161, 242)}
.icon{ width: 60px; height: 60px; margin-top: 10px; line-height: 60px; font-size: 40px; color: #fff; border-radius: 50%; background: rgb(49, 180, 141); }
.info{ width: calc(100% - 80px); text-align: right; font-size: 14px;}
.info span{ font-size: 12px;}
.bg{background: #fff;}
.in3{ width: 180px;}
</style>
